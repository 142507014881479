import React from "react";
import Link from "next/link";

const MenuLink: React.FC<{
  href: string;
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
}> = ({ href, isActive, onClick, children }) => (
  <Link
    className={`${isActive ? "border-b font-bold" : ""} text-end`}
    href={href}
    onClick={onClick}
  >
    {children}
  </Link>
);

export default MenuLink;
