import Link from "next/link";
import React from "react";

const NavLink: React.FC<{
  href: string;
  icon: React.ComponentType<{ className?: string }>;
  isActive: boolean;
}> = ({ href, icon: Icon, isActive }) => (
  <Link href={href} className="text-2xl">
    <Icon
      className={`${isActive ? "text-white stroke-[20px] stroke-black" : ""}`}
    />
  </Link>
);

export default NavLink;
