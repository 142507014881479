import pino from "pino";

const logger = pino({
  level: "debug",
  timestamp: pino.stdTimeFunctions.isoTime,
  // serializers:
  browser: {
    asObject: true,
    write: (o) => console.log(JSON.stringify(o)),
    // write(obj) {
    //   try {
    //     logger.info(JSON.stringify(obj));
    //   } catch (err) {
    //     if (err instanceof Error) {
    //       // Without a `replacer` argument, stringify on Error results in `{}`
    //       logger.info(JSON.stringify(err, ["name", "message", "stack"]));
    //     } else {
    //       logger.info(JSON.stringify({ message: "Unknown error type" }));
    //     }
    //   }
    // },
  },
});

export { logger };
