"use client";

import React from "react";
import { X } from "lucide-react";
import { ClientUser } from "@/types";
import { signOut } from "next-auth/react";
import MenuLink from "./MenuLink";
import Link from "next/link";
import { usePathname } from "next/navigation";

interface MenuProps {
  user: ClientUser | null;
  isAdmin: boolean;
  isAtBottom: boolean;
  isMenuOpen: boolean;
  toggleMenu: () => void;
}

const Menu: React.FC<MenuProps> = ({
  user,
  isAdmin,
  isAtBottom,
  isMenuOpen,
  toggleMenu,
}) => {
  const pathname = usePathname();

  const menuPosition = isAtBottom ? "mt-2 top-full" : "mb-2 bottom-full";
  const menuVisibility = !isMenuOpen ? "h-0" : "h-fit py-4 border";

  return (
    <div
      className={`sm:hidden fixed z-10 bg-gray-100 overflow-hidden left-0 right-0 origin-top duration-700 border-gray-300 ${menuVisibility} ${menuPosition}`}
    >
      <div className="px-8 w-full">
        <div
          className={`flex flex-col ${isAtBottom ? "flex-col-reverse" : ""} gap-8 tracking-wider`}
        >
          <X className="cursor-pointer h-8 w-8 self-end" onClick={toggleMenu} />
          {user && (
            <MenuLink
              href="/account"
              isActive={pathname.includes("account")}
              onClick={toggleMenu}
            >
              Profile
            </MenuLink>
          )}
          {isAdmin && (
            <MenuLink
              href="/tournaments"
              isActive={pathname.includes("tournaments")}
              onClick={toggleMenu}
            >
              Tournaments
            </MenuLink>
          )}
          {user && (
            <MenuLink
              href="/correct_pgn"
              isActive={pathname === "/correct_pgn"}
              onClick={toggleMenu}
            >
              Add PGN
            </MenuLink>
          )}
          {!user && (
            <MenuLink
              href="/sign-in"
              isActive={pathname === "/sign-in"}
              onClick={toggleMenu}
            >
              Sign In
            </MenuLink>
          )}
          {user && (
            <Link
              href="/"
              className="text-end"
              onClick={(event) => {
                event.preventDefault();
                signOut({ callbackUrl: `${window.location.origin}/sign-in` });
              }}
            >
              Sign Out
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
