"use client";

import { ClientUser } from "@/types/ClientUser";
import { usePathname } from "next/navigation";
import React, { useState, useEffect, useRef } from "react";
import { FaHome, FaChess, FaFolder } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { UserAvatar } from "../UserAvatar";
import { isAdminUser } from "@/lib/utils";
import Menu from "./Menu";
import NavLink from "./NavLink";
import { useUserContext } from "../providers/user-provider";

const MobileNavbar: React.FC<{ user: ClientUser | null }> = ({ user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const lastScrollY = useRef(0);

  const pathname = usePathname();
  const userContext = useUserContext();
  const isAdmin = isAdminUser(user);

  // debounce function
  function debounce<T extends (...args: any[]) => any>(
    func: T,
    delay: number,
  ): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    return (...args: Parameters<T>) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  }

  useEffect(() => {
    lastScrollY.current = 0;

    const scrollableWrapper = document.getElementById(
      "layout-children-wrapper",
    );

    const handleScroll = () => {
      let currentScrollY = window.scrollY;

      const body = document.body;
      const html = document.documentElement;
      let height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );

      let nearBottom = currentScrollY + window.innerHeight >= height - 100;

      if (scrollableWrapper) {
        height = scrollableWrapper.scrollHeight;
        currentScrollY = scrollableWrapper.scrollTop;
        nearBottom = currentScrollY + window.innerHeight >= height - 100;
      }
      const isScrollingDown = currentScrollY > lastScrollY.current;

      setIsVisible(
        userContext.imageFullScreen ? false : !isScrollingDown || nearBottom,
      );
      setIsAtBottom(nearBottom);
      lastScrollY.current = currentScrollY;
    };

    const debouncedHandleScroll = debounce(handleScroll, 50);

    window.addEventListener("scroll", debouncedHandleScroll);
    scrollableWrapper?.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
      scrollableWrapper?.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [pathname, userContext]);

  useEffect(() => {
    setIsVisible(true);
  }, [pathname]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navbarPosition = isAtBottom
    ? "top-0 border-b-2"
    : "bottom-0 border-t-2";
  const navbarVisibility = isVisible
    ? "transform translate-y-0"
    : isAtBottom
      ? "transform -translate-y-full"
      : "transform translate-y-full";

  return (
    <nav
      className={`sm:hidden fixed ${navbarPosition} left-0 right-0 bg-gray-100 py-2 px-4 z-10 border-gray-200 transition-all duration-300 ease-in-out ${navbarVisibility}`}
    >
      <div className="flex justify-between items-center">
        <Menu
          user={user}
          isAdmin={isAdmin}
          isAtBottom={isAtBottom}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
        />

        <NavLink href="/" icon={FaHome} isActive={pathname === "/"} />
        <NavLink
          href={user ? "/collections/me" : "/sign-in"}
          icon={FaFolder}
          isActive={pathname.startsWith("/collections")}
        />
        <NavLink
          href="/game/new_batch"
          icon={FaCirclePlus}
          isActive={pathname === "/game/new_batch"}
        />
        <NavLink
          href="/games"
          icon={FaChess}
          isActive={
            pathname.startsWith("/game") && !pathname.includes("new_batch")
          }
        />

        <button onClick={toggleMenu} className="text-2xl">
          <UserAvatar
            user={{
              name: user?.name
                ? user.name.charAt(0).toUpperCase() + user.name.slice(1)
                : null,
              image: user?.image || null,
            }}
            className={`h-9 w-9 ${pathname.includes("account") ? "ring-2 ring-blue-400 ring-offset-2" : ""}`}
          />
        </button>
      </div>
    </nav>
  );
};

export default MobileNavbar;
